import React from 'react'

// import styles
import styles from './BeforeAfter.module.css'

// import components
import Line from './Line'

// import images
import aboutNew from '../images/mcv-web-rebrand/about_new.jpg'
import aboutOld from '../images/mcv-web-rebrand/about_old.jpg'
import heroNew from '../images/mcv-web-rebrand/hero_new.jpg'
import heroOld from '../images/mcv-web-rebrand/hero_old.jpg'
import philNew from '../images/mcv-web-rebrand/phil_new.jpg'
import philOld from '../images/mcv-web-rebrand/phil_old.jpg'
import portNew from '../images/mcv-web-rebrand/port_new.jpg'
import portOld from '../images/mcv-web-rebrand/port_old.jpg'
import whatNew from '../images/mcv-web-rebrand/what_new.jpg'
import whatOld from '../images/mcv-web-rebrand/what_old.jpg'
import logoNew from '../images/mcv-web-rebrand/logo_new.svg'
import logoOld from '../images/mcv-web-rebrand/logo_old.svg'
import colorNew from '../images/mcv-web-rebrand/colors_new.svg'
import colorOld from '../images/mcv-web-rebrand/colors_old.svg'

export default () => (
    <div className={styles.container}>
        <div className={styles.flexContainer}>
            <div>
                <Line color={'black'}/>
                <h3>ORIGINAL</h3>
                <p>The logo incorporated the letters M, C, and V. Though interesting, the use of the sans serif logo, paired with the serif lettering underneath, felt mismatched and lacked corporate appeal. Excessive use of orange tones created a distracting look.</p>
                <p>The layout of the original site was broken up into pages with just a hero image and mission statement as the home page. In addition, subtle curved edges were used within the color blocks, creating a sense of motion that was not utilized enough to be effective.</p>
            </div>
            <div>
                <Line color={'black'}/>
                <h3>REBRAND</h3>
                <p>The new logo incorporates a serif font and was simplified into an icon that represents the brand without having to incorporate too many letters. A matching description line in the same font creates a more traditional/corporate feel. The same primary orange remains the dominate color with shades of grey to balance the brightness.</p>
                <p>The new home page is designed in simplified sections to guide the viewer further into the website. Rounded corners were incorporated throughout the page as a nod to the original curves and as a branding identifier.</p>
            </div>
        </div>
        <div className={styles.flexContainer}>
            <div className={styles.color}>
                <img src={colorOld} alt='old color scheme' />
                <p>Original</p>
            </div>
            <div className={styles.color}>
                <img src={colorNew} alt='new color scheme' />
                <p>Rebrand</p>
            </div>
        </div>
        <div className={styles.flexContainer}>
            <div className={styles.logo}>
                <img src={logoOld} alt='old logo' />
                <p>Original</p>
            </div>
            <div className={styles.logo}>
                <img src={logoNew} alt='new logo' />
                <p>Rebrand</p>
            </div>
        </div>
        <div className={styles.flexContainer}>
            <div className={styles.screenshot}>
                <img src={heroOld} alt='old hero' />
                <p>Original</p>
            </div>
            <div className={styles.screenshot}>
                <img src={heroNew} alt='new hero' />
                <p>Rebrand</p>
            </div>
        </div>
        <div className={styles.flexContainer}>
            <div className={styles.screenshot}>
                <img src={whatOld} alt='old what we do section' />
                <p>Original</p>
            </div>
            <div className={styles.screenshot}>
                <img src={whatNew} alt='new what we do section' />
                <p>Rebrand</p>
            </div>
        </div>
        <div className={styles.flexContainer}>
            <div className={styles.screenshot}>
                <img src={portOld} alt='old portfolio section' />
                <p>Original</p>
            </div>
            <div className={styles.screenshot}>
                <img src={portNew} alt='new portfolio section' />
                <p>Rebrand</p>
            </div>
        </div>
        <div className={styles.flexContainer}>
            <div className={styles.screenshot}>
                <img src={aboutOld} alt='old about section' />
                <p>Original</p>
            </div>
            <div className={styles.screenshot}>
                <img src={aboutNew} alt='new about section' />
                <p>Rebrand</p>
            </div>
        </div>
        <div className={styles.flexContainer}>
            <div className={styles.screenshot}>
                <img src={philOld} alt='old philanthropy section' />
                <p>Original</p>
            </div>
            <div className={styles.screenshot}>
                <img src={philNew} alt='new philanthropy section' />
                <p>Rebrand</p>
            </div>
        </div>
    </div>
)