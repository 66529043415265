import React from 'react'

//import components
import Project from '../components/Project'
import Padding from '../components/Padding'
import FullWidthImage from '../components/FullWidthImage'
import NextSections from '../components/NextSections'
import FullView from '../components/MCVFullView'
import BeforeAfter from '../components/BeforeAfter'
import GithubLink from '../components/GithubLink'

// import images
import mockup from '../images/mcv-web-rebrand/mockup_redo.jpg'
import desktop from '../images/mcv-web-rebrand/desktop.jpg'
import mobile from '../images/mcv-web-rebrand/mobile.jpg'

export default () => (
    <Project
        title={'mcv web rebrand'}
            date={'2019'}
            position={'Personal Project'}
            employer={''}
            brief={[
                'Mort Collins Ventures (MCV) is a Venture Capitalist Fund, founded and operated by Dr. Collins. His current website features general information about the fund, with emphasis on his professional investment achievements and impressive board affiliations. However, the official website lacked impact and therefore effectiveness due to it’s restricted and copy-heavy interface.',
                'As a personal goal, I challenged myself with rebranding and redesigning the MCV home page. Therefore, this was an exercise in creating an MVP (minimum viable product) to show the possibilities of what the rebrand could look like.',
                'Inspiration was taken from the existing brand, site, and copy to create a developed prototype. Unlike the original site, both desktop and mobile interfaces were considered and designed, creating a more modern aesthetic that would entice any entrepreneur.'
            ]}
            skills={[
                'XD',
                'Illustrator',
                'Branding',
                'HTML',
                'CSS'
            ]}
    >
        <FullWidthImage src={mockup} alt={'Person holding computer and phone'} />
        <FullView 
            desktop={desktop}
            mobile={mobile}
            backgroundColor={'#EEEEEE'}
            desktopLink={'https://xd.adobe.com/view/bbfb164e-4289-4de0-7b6e-1717b8ec74a5-cdca/'}
            mobileLink={'https://xd.adobe.com/view/6b285678-9fa2-4dad-413e-ffc28e90d4f3-1d19/'}
            codeLink={'https://sophievakalis.github.io/mcv-redesign/'}
            color={'#EC4025'}
        />
        <Padding />
        <BeforeAfter />
        <Padding size={'s'}/>
        <GithubLink background={true}/>
        <Padding size={'s'}/>
        <NextSections
            previous={'visual-narratives'}
            next={'make-me-chic-email'}
        />
    </Project>
)